import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Icon, Box, Image, Section } from "@quarkly/widgets";
import { FaEnvelope } from "react-icons/fa";
import { MdPhone, MdLocationOn } from "react-icons/md";
const defaultProps = {
	"padding": "80px 0 80px 0",
	"sm-padding": "60px 0px 60px 0px"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"width": "50%",
			"flex-direction": "column",
			"justify-content": "flex-start",
			"align-items": "flex-start",
			"lg-width": "100%",
			"lg-align-items": "flex-start",
			"lg-margin": "0px 0px 60px 0px",
			"sm-margin": "0px 0px 40px 0px",
			"sm-padding": "0px 0px 0px 0px",
			"padding": "24px 0px 16px 0px",
			"lg-flex-direction": "row",
			"lg-flex-wrap": "wrap"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 24px 0px",
			"color": "--dark",
			"font": "--headline1",
			"lg-text-align": "center",
			"sm-font": "normal 700 42px/1.2 \"Source Sans Pro\", sans-serif",
			"lg-width": "100%",
			"children": "Contacte con nosotros"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 48px 0px",
			"color": "--greyD3",
			"font": "--lead",
			"lg-text-align": "center",
			"lg-width": "100%",
			"children": "Nos encantaría saber de usted y discutir cómo PristineOffice Cleaning Services puede satisfacer sus necesidades de limpieza de oficinas."
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"align-items": "flex-start",
			"margin": "0px 0px 32px 0px",
			"lg-width": "50%",
			"lg-margin": "0px 0px 0px 0px",
			"lg-padding": "0px 16px 0px 0px",
			"md-width": "100%",
			"md-margin": "0px 0px 32px 0px",
			"md-padding": "0px 0px 0px 0px"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"padding": "7px 24px 8px 24px",
			"margin": "0px 0px 0px 0px",
			"font": "--headline3",
			"background": "#60bae1",
			"border-radius": "50px",
			"align-items": "center",
			"display": "flex",
			"justify-content": "center",
			"color": "--light",
			"width": "49px",
			"height": "49px",
			"text-align": "center"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "fa",
			"icon": FaEnvelope,
			"size": "54px",
			"color": "--darkL1",
			"padding": "12px 12px 12px 12px"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 0px 22px"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 8px 0px",
			"color": "--darkL2",
			"font": "--headline3",
			"lg-text-align": "left",
			"children": "support@serenitrefuge.com"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--greyD3",
			"font": "--base",
			"lg-text-align": "left",
			"children": "Email"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"align-items": "flex-start",
			"margin": "0px 0px 32px 0px",
			"lg-width": "50%",
			"lg-padding": "0px 0px 0px 16px",
			"md-width": "100%",
			"md-padding": "0px 0px 0px 0px"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"padding": "7px 24px 8px 24px",
			"margin": "0px 0px 0px 0px",
			"font": "--headline3",
			"background": "#60bae1",
			"border-radius": "50px",
			"display": "flex",
			"align-items": "center",
			"justify-content": "center",
			"color": "--light",
			"width": "49px",
			"height": "49px"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"category": "md",
			"icon": MdPhone,
			"size": "54px",
			"color": "--darkL1",
			"padding": "12px 12px 12px 12px"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 0px 22px"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 8px 0px",
			"color": "--darkL2",
			"font": "--headline3",
			"lg-text-align": "left",
			"children": "+34 611 684 243"
		}
	},
	"text7": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--greyD3",
			"font": "--base",
			"lg-text-align": "left",
			"children": "Teléfono"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"align-items": "flex-start",
			"lg-width": "50%",
			"lg-padding": "0px 0px 0px 16px",
			"md-width": "100%",
			"md-padding": "0px 0px 0px 0px"
		}
	},
	"text8": {
		"kind": "Text",
		"props": {
			"padding": "7px 24px 8px 24px",
			"margin": "0px 0px 0px 0px",
			"font": "--headline3",
			"background": "#60bae1",
			"border-radius": "50px",
			"display": "flex",
			"align-items": "center",
			"justify-content": "center",
			"color": "--light",
			"width": "49px",
			"height": "49px"
		}
	},
	"icon2": {
		"kind": "Icon",
		"props": {
			"category": "md",
			"icon": MdLocationOn,
			"size": "54px",
			"color": "--darkL1",
			"padding": "12px 12px 12px 12px"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 0px 22px"
		}
	},
	"text9": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 8px 0px",
			"color": "--darkL2",
			"font": "--headline3",
			"lg-text-align": "left",
			"children": "C. Antonio Gala 16, 41008 Sevilla, España"
		}
	},
	"text10": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--greyD3",
			"font": "--base",
			"lg-text-align": "left",
			"children": "Localización"
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"width": "50%",
			"justify-content": "flex-end",
			"overflow-y": "hidden",
			"overflow-x": "hidden",
			"lg-width": "100%",
			"padding": "0px 0px 0px 16px",
			"align-items": "center",
			"lg-justify-content": "center",
			"lg-padding": "0px 0px 0px 0px"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://serenitrefuge.com/img/contact.png",
			"max-width": "100%",
			"transform": "translateY(10px)",
			"transition": "transform 0.5s ease-in-out 0s",
			"hover-transform": "translateY(0px)"
		}
	}
};

const Contact = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
			<Box {...override("box1")}>
				<Text {...override("text2")}>
					<Icon {...override("icon")} />
				</Text>
				<Box {...override("box2")}>
					<Text {...override("text3")} />
					<Text {...override("text4")} />
				</Box>
			</Box>
			<Box {...override("box3")}>
				<Text {...override("text5")}>
					<Icon {...override("icon1")} />
				</Text>
				<Box {...override("box4")}>
					<Text {...override("text6")} />
					<Text {...override("text7")} />
				</Box>
			</Box>
			<Box {...override("box5")}>
				<Text {...override("text8")}>
					<Icon {...override("icon2")} />
				</Text>
				<Box {...override("box6")}>
					<Text {...override("text9")} />
					<Text {...override("text10")} />
				</Box>
			</Box>
		</Box>
		<Box {...override("box7")}>
			<Image {...override("image")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Contact, { ...Section,
	defaultProps,
	overrides
});
export default Contact;